import React from "react"
import { Link, graphql } from "gatsby"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import Header from "../components/header/header"
import HeaderMobile from "../components/header/headerMobile"
import Footer from "../components/footer/footer"
import FooterMobile from "../components/footer/footerMobile"

import styled from "styled-components"
import font from "../fonts/fonts.module.scss"

const NewsTemplate = ({ data }) => {

  return (
    <Layout>
      <SEO title={data.markdownRemark.frontmatter.title} image={data.markdownRemark.frontmatter.image.childImageSharp.fluid.src} />
      {useBreakpoint().mobile ? (
        <>
          <HeaderMobile data={data.globalJson.header} services={false} />
          <BackgroundStyled bg={`url("${data.noticiasJson.introMobile.backgroundscroll.childImageSharp.fluid.src}")`} />
          <NewsStyled>
            <StyledContent dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }} />
            <StyledLink>
              <a href={"/noticias"} className={font.oL}>Voltar</a>
            </StyledLink>
            <FooterMobile data={data.globalJson.footer} long floatButtons={data.globalJson.floatButtons} />
          </NewsStyled>
        </>
      ) : (
        <>
          <Header data={data.globalJson.header} services={false} />
          <BackgroundStyled bg={`url("${data.noticiasJson.intro.backgroundscroll.childImageSharp.fluid.src}")`} />
          <NewsStyled>
            <StyledContent dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }} />
            <StyledLink>
              <a href={"/noticias"} className={font.oL}>Voltar</a>
            </StyledLink>
            <Footer data={data.globalJson.footer} long floatButtons={data.globalJson.floatButtons} />
          </NewsStyled>
        </>
      )}

    </Layout>
  )
}

export default NewsTemplate

const BackgroundStyled = styled.div`
background-repeat: no-repeat;
background-position: top;
background-size: cover;
background-image:  ${(props) => props.bg || ''};
  left: 0;
  right: 0;
  z-index: -99;
  display: block;
  width: 100%;
  height: 100vh;
  position: fixed;
`

const NewsStyled = styled.div`
position: absolute;
left: 0;
right: 0;
z-index: 99;
`

const StyledLink = styled.div`
  width: 100%;
  margin: 30px 0;
  a {
    width: fit-content;
    margin: auto;
    text-decoration: underline;
    font-size: 1rem;
  }
`

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 1200px;
  margin: 100px auto 0;  

  .video{
    height: 650px;
    width: 100%;
  }

  @media only screen and (max-width: 1300px){
    width: 90%;
    .video{
      height: 55vw;
    }
  }
`

export const Json = graphql`
  query newsTemplate($slug: String!) {
    noticiasJson{
      intro {
        background {
          childImageSharp {
            fluid(quality: 100, maxWidth: 3400) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        backgroundscroll {
          childImageSharp {
            fluid(quality: 100, maxWidth: 3400) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      introMobile {
        background {
          childImageSharp {
            fluid(quality: 100, maxWidth: 768) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        backgroundscroll {
          childImageSharp {
            fluid(quality: 100, maxWidth: 768) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    globalJson {
      header {
        links {
          link
          linkTxt
        }
        sidemenu {
          links {
            link
            linkTxt
            extraLinks {
              link
              linkTxt
            }
          }
          madeBy
        }
      }
      floatButtons{
        btn
        btnLink
      }
      footer {
        linksLeft {
          link
          linkTxt
        }
        linksRight {
          link
          linkTxt
        }
        image {
          childImageSharp {
            fluid(maxWidth: 198, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      noticias{
        bg {
          childImageSharp {
            fluid(quality: 100, maxWidth: 3400) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        bgMobile {
          childImageSharp {
            fluid(quality: 100, maxWidth: 3400) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        text
        date(formatString: "DD-MM-YYYY")
        image {
          childImageSharp {
            fluid(quality: 100, maxWidth: 3000) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      html
    }
  }
`
